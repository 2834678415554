<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="credit-card" />
				<span>交易</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 账务查询 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="search-list-box">
			<a-form class="ant-advanced-search-form">
				<a-row :gutter="24">
					<a-col :span="4">
						<a-form-item :label="`设备编号：`">
							<a-input placeholder="请输入设备编号" v-model="searchData.number" />
						</a-form-item>
					</a-col>
					<a-col :span="4">
						<a-form-item :label="`设备名称：`">
							<a-input placeholder="请输入设备名称" v-model="searchData.name" />
						</a-form-item>
					</a-col>
					<a-col :span="6">
						<a-form-item :label="`选择日期：`">
							<a-range-picker  :disabledDate="disabledDate" :ranges="{ '今天': [moment(), moment()] }" :key="pickerKey" @change="handleChangeDate" style="width: 120px" format="YYYY-MM-DD" />
						</a-form-item>
					</a-col>
					<a-col :span="4">
						<a-button type="primary" @click="getList"> 查询 </a-button>
						<a-button :style="{ marginLeft: '8px' }" @click="handleReset">
							重置
						</a-button>
					</a-col>
				</a-row>
			</a-form>
			<div class="search-result-list">
				<div class="table-header-box">
					<div class="left">
						<h3 class="title">账务查询</h3>
					</div>
					<div class="right">
						<a-button type="primary" class="marginr20" @click="showDetail">
							查看明细
						</a-button>
						<a-button type="primary" class="marginr20" @click="pushSummary">
							汇总推送
						</a-button>
					</div>
				</div>
				<a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns" @change="handleTableChange"
				 :pagination="{total: total_page * 10}" :data-source="list" :scroll="{ x: 1500 }" rowKey="record_id" size="small">
				</a-table>
			</div>
		</div>

		<a-modal v-model="detailShow" width="1150px"  title="账务交易明细" :footer="null">
			<a-spin :spinning="detailLoading">
				<a-table :columns="columns1" :pagination="{total: total_detail_page * 10}" :data-source="detailList" :scroll="{ x: 1050 }" rowKey="tran_id" size="small" @change="TableChange">
					
				</a-table>
				<div class="model-footer">
					<div class="left">
						<a-button type="primary" class="marginr20" html-type="submit" @click="detailShow = false">
							取消
						</a-button>
					</div>
					<div class="right">
						<a-button type="dashed" :disabled="isEditBtn('prev')" @click="editToData('prev')" class="" html-type="submit">
							上一条
						</a-button>
						<a-button type="dashed" :disabled="isEditBtn('next')" @click="editToData('next')" class="marginr20" html-type="submit">
							下一条
						</a-button>
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import moment from 'moment';
	import http from '../http'

	const columns = [{
			title: "设备编号",
			dataIndex: "number",
			width:120,
		},
		{
			title: "所属区域",
			dataIndex: "area",
			width:100,
		},
		{
			title: "上次加币时间",
			dataIndex: "start_time",
			width:150,
		},
		{
			title: "清机时间",
			dataIndex: "end_time",
			width:150,
		},
		{
			title: "上次加币金额(元)",
			dataIndex: "add_amount",
			width:140,
		},
		{
			title: "本次余额(元)",
			dataIndex: "amount",
			width:100,
		},
		{
			title: "清机总金额(元)",
			dataIndex: "clear_amount",
			width:120,
		},
		{
			title: "清机1元(枚)",
			dataIndex: "clear_out100",
			width:90,
		},
		{
			title: "清机5角(枚)",
			dataIndex: "clear_out50",
			width:90,
		},
		{
			title: "清机1角(枚)",
			dataIndex: "clear_out10",
			width:90,
		},
		{
			title: "微信入账金额(元)",
			dataIndex: "wx_inamount",
		},
		{
			title: "微信出账金额(元)",
			dataIndex: "wx_outamount",
		},
		{
			title: "支付宝入账金额(元)",
			dataIndex: "ali_inamount",
		},
		{
			title: "支付宝出账金额(元)",
			dataIndex: "ali_outamount",
		},
		{
			title: "出入账误差(元)",
			dataIndex: "no_amount",
		},
	];

	const columns1 =  [
		{
			title: "交易单号",
			dataIndex: "trade_no",
			width:140,
		},
		{
			title: "终端编号",
			dataIndex: "number",
			width:110,
		},
		{
			title: "交易类型",
			dataIndex: "tname",
			width:80,
		},
		{
			title: "交易结果",
			dataIndex: "remark",
			width:130,
		},
		{
			title: "支付时间",
			dataIndex: "add_time",
			width:135,
		},
		{
			title: "金额(元)",
			dataIndex: "amount",
			width:70,
		},
		{
			title: "交易账户",
			dataIndex: "third_uid",
			width:240,
		},
		{
			title: "状态",
			dataIndex: "status_txt",
			width:70,
		},

	];
	export default {
		data() {
			return {
				expand: false,
				columns,
				columns1,
				detailShow: false,
				searchData: {
					page: 1,
					node_id: undefined,
					bank_id: undefined,
					number:'',
					name:'',
					start_date:'',
					end_date:'',
				},
				list: [],
				total_page: 0,
				pickerKey: Math.random(),
				selectedRowKeys: [],
				selectedRowDataList: [],
				editIndex: 0,
				detailLoading: false,
				detailList: [],
				total_detail_page: 0,
				bankTreeData: [],
				outletsList: [],
				tpage:0,
			};
		},
		mounted() {
			this.getList()
		},
		created () {
			this.getBankTreeData()
			this.getOutletsList()
		},
		methods: {
			moment,
			disabledDate(current) {
			     return current && current >= moment().subtract("days"); //当天之前的不可选，不包括当天
			   //return current && current < moment().endOf(‘day’);当天之前的不可选，包括当天
			},
			handleReset() {
				this.pickerKey = Math.random()
				this.searchData = {
					page: 1,
					node_id: undefined,
					bank_id: undefined,
					number:'',
					name:'',
					start_date:'',
					end_date:'',
				}
				this.getList();
			},
			// 表格分页
			handleTableChange(pagination, filters, sorter) {
				this.searchData.page = pagination.current
				this.getList();
			},
			// 表格分页
			TableChange(pagination, filters, sorter) {
				this.tpage = pagination.current
				this.getDetail();
			},
			// 表格选择
			onSelectChange(selectedRowKeys) {
				this.selectedRowKeys = selectedRowKeys;
			},
			//汇总推送
			pushSummary(){
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择数据');
					return
				}
				let selectDatas = []
				this.list.forEach((v) => {
					if (this.selectedRowKeys.includes(v.record_id)) {
						selectDatas.push(v.record_id)
					}
				})
				let selectDatasList = JSON.stringify(selectDatas);
				this.$confirm({
					title: '是否确定汇总推送选中记录?',
					okText: '确定',
					okType: 'primary',
					cancelText: '取消',
					onOk : () => {
						this.detailLoading = true
						http({
							method: 'post',
							url: '/api.account.summary/',
							data: {
								rid: selectDatasList
							}
						}).then((res) => {
							this.detailLoading = false
							if(res.code=="200"){
								this.$message.success('汇总推送操作成功');
							}else{
								this.$message.warning('汇总推送操作失败');
							}
						})
					}
				});
			},
			//查看详情
			showDetail() {
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择数据');
					return
				}
				let selectDatas = []
				this.list.forEach((v) => {
					if (this.selectedRowKeys.includes(v.record_id)) {
						selectDatas.push(v)
					}
				})
				this.selectedRowDataList = JSON.parse(JSON.stringify(selectDatas))
				this.editIndex = 0
				// 获取详情
				this.getDetail()
				this.detailShow = true
			},
			// 详情
			getDetail() {
				this.detailLoading = true
				http({
					method: 'post',
					url: '/api.transaction.cadetail/',
					data: {
						record_id: this.selectedRowDataList[this.editIndex].record_id,
						page: this.tpage
					}
				}).then((res) => {
					this.detailList = res.data.list
					this.total_detail_page  =res.data.total_page
					this.detailLoading = false
				})
			},
			// 点击上一条 或者 下一条、
			editToData(type) {
				let index = -1
				if (type == 'prev') {
					index = this.editIndex - 1
					if (index == 0) {
						index = 0
					}
				} else if (type == 'next') {
					index = this.editIndex + 1
				}
				this.editIndex = index
				this.getDetail()
			},
			// 判断是否可以点击上一条 或者 下一条、
			isEditBtn(type) {
				if (type == 'prev') {
					if (this.editIndex == 0) {
						return true
					}
					if (this.selectedRowDataList == 1) {
						return true
					}
					if (this.editIndex >= 1) {
						return false
					}
				} else if (type == 'next') {
					if ((this.selectedRowDataList.length - 1) == this.editIndex) {
						return true
					}
				}
			},
			// 列表
			getList() {
				http({
					method: 'post',
					url: '/api.transaction.calist/',
					data: {
						...this.searchData,
						bank_id: this.searchData.bank_id || 0,
						node_id: this.searchData.node_id || 0,
					}
				}).then((res) => {
					this.list = res.data.list
					this.total_page = res.data.total_page
				})
			},
			// 日期解析
			handleChangeDate(date) {
				this.searchData.start_date = this.moment(date[0]).format('YYYY-MM-DD')
				this.searchData.end_date = this.moment(date[1]).format('YYYY-MM-DD')
			},
			// 获取银行类型
			getBankTreeData() {
				http({
					method: 'post',
					url: '/api.bank.list/',
					data: {
						page: 1,
						parent_id: 0
					}
				}).then((res) => {
					this.bankTreeData = this.dataDealwith(res.data)
				})
			},
			// 获取网点
			getOutletsList() {
				http({
					method: 'post',
					url: '/api.bank.node.list/',
					data: {
						page: 1,
						name: '',
						bank_id: 0
					}
				}).then((res) => {
					this.outletsList = res.data.list
				})
			},
			dataDealwith(treeData) {
				function setGrayNode(data) { //遍历树  获取id数组
					for (var i = 0; i < data.length; i++) {
						data[i] = {
							value: data[i].bank_id,
							key: data[i].bank_id,
							title: data[i].name,
							children: data[i].list || []
						} //子节点传给另一个函数专门用来改数据
						if (data[i].children && data[i].children.length) { // 如果当前节点有子节点，就递归调用一次
							setGrayNode(data[i].children);
						}
					}
				}
				setGrayNode(treeData)
				return treeData;
			},
		},
	};
</script>
<style>
	.ant-advanced-search-form {
		padding: 24px;
		background: #fbfbfb;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
	}

	.ant-advanced-search-form .ant-form-item {
		display: flex;
	}

	.ant-advanced-search-form .ant-form-item-control-wrapper {
		flex: 1;
	}

	.search-list-box .ant-form {
		max-width: none;
	}

	.search-list-box .search-result-list {
		margin-top: 16px;
		border: 1px dashed #e9e9e9;
		border-radius: 6px;
		background-color: #fafafa;
		min-height: 200px;
		text-align: center;
		padding-top: 80px;
	}

	.imgs {
		display: flex;
	}

	.imgs img {
		width: 140px;
		height: 200px;
	}
</style>
